@import "src/styles/variables";

.spaceLogo {
  margin-top: 24px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > span {
    margin-top: 6px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
      fill: white;
      max-width: 22px;
      max-height: 22px;
    }
  }
}

.menuContainer {
  width: 100%;
  margin-top: 24px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  > div {
    //background-color: $secondary-color;
    padding: 4px 12px;
    color: white;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-radius: 6px;
    font-size: 18px;
    overflow: hidden;
    font-weight: 600;
    transition: background-color 50ms linear;
  }
}

.innerContainer {
  width: 280px;
  // padding-left: 14px;
  // padding-right: 14p x;
  // border: 1px solid black;

  .popoverLogo {
    padding: 4px 15px;
    display: flex;
    align-items: center;
    width: 100%;

    > span {
      margin-left: 6px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
    }
  }

  > button {
    text-align: left;
    width: 100%;
    font-size: 14px;
    // font-weight: 500;
    height: 40px;
  }

  > a {
    text-align: left;
    width: 100%;
    font-size: 14px;
    // font-weight: 500;
    height: 40px;
    display: flex;
    align-items: center;
    color: black;
    &:hover {
      color: black !important;
    }
  }

  > div {
    // padding: 14px;

    .header {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
      display: block;
    }
    .subHeader {
      padding: 4px 15px;
      display: block;
      font-size: 13px;
      font-weight: 400;

      > a {
        font-weight: 600;
        color: black;
        text-decoration: underline;
      }
    }

    .special {
      padding: 4px 15px;

      display: block;
      background: #05aaff;
      color: white;
      font-size: 13px;
      font-weight: 600;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.divider {
  margin-top: 8px;
  border: 0.07em solid #e3e3e3;
  margin-bottom: 8px;
  margin-left: -12px;
  margin-right: -12px;
}
