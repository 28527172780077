@import "../../../styles/variables";

.switch {
  margin-top: 32px;
  padding-top: 12px;
  border-top: 2px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  > span {
    max-width: 80%;
    font-size: 15px;
  }
}

.date {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
}

.description {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 14px;
}

.background {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 12px;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 12px;
  }

  img {
    object-fit: contain !important;
  }
}
