@import "src/styles/variables";

.container {
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 12px;
  color: white;
  display: flex;
  justify-content: space-between;

  .button {
    cursor: pointer;
    transition: background-color 50ms linear;
    width: 48%;
    color: white;
    padding: 8px 2px;
    border-radius: 6px;
    font-size: 12px;

    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;

    > svg {
      margin-right: 6px;
    }
    &:hover {
      background: $active-color;
    }
  }

  //.redButton {
  //  cursor: pointer;
  //  transition: background-color 50ms linear;
  //  background: rgb(225 70 0 / 42%);
  //  width: 48%;
  //  padding: 8px 4px;
  //  border-radius: 6px;
  //
  //  display: flex;
  //  align-items: center;
  //  font-weight: 600;
  //  justify-content: center;
  //
  //  &:hover {
  //    background: rgb(225 70 0 / 100%);
  //  }
  //
  //  svg {
  //    width: 24px;
  //    height: 24px;
  //    margin-right: 6px;
  //  }
  //}
}
