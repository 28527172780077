@import "src/styles/variables";

.connection {
  // position: absolute;
  // right: -24px;
  // top: 4px;
  // width: 14px;
  // height: 15px;
  margin-right: 4px;
}
.muteIcon {
  // position: absolute;
  // bottom: 40px;
  // right: 22px;
  z-index: 1;
  width: 23px;
  height: 28px;
}

.container {
  position: relative;

  // overflow-y: scroll;
  // height: 100vh;

  // background: rgba(32, 32, 38, 1);
}

.screenShareWrapper {
  background: rgb(14 15 18 / 94%);
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 5px;
  border-radius: 11px;
  width: 94%;
  height: 85%;
  z-index: 1111;
}

.name {
  height: 26px;
  padding: 0 4px;
  width: max-content;
  margin: 8px auto 0 auto;
  background: rgb(29 35 41 / 90%);
  text-align: center;
  color: white;
  border-radius: 4px;
  border: 4px solid transparent;
  position: relative;

  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;

  display: flex;
  > div {
    display: flex;
    align-items: center;
    max-width: 150px;

    > span {
      display: flex;
      align-items: center;

      overflow: hidden;
      white-space: nowrap;
      max-width: 112px;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }
}

.presenter {
  border: 4px solid $active-color;
}

.presenterDiv {
  padding: 0 4px;
  background: $active-color;
  color: white;
  width: max-content;
  margin: 0 auto;
  border-radius: 0 0 6px 6px;
  font-weight: 500;
  font-size: 10px;
}
.participant {
  background: $background-color;
  color: white;
  width: 180px;
  height: 180px;
  border-radius: 8px;
  position: relative;
  border: 3px solid rgba(16, 11, 24, 0.7);

  //overflow: hidden;
  // box-shadow: inset 0px 0px 20px 5px rgba(0, 0, 0, 0.6);
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    background: #181b1d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.video {
  z-index: 1;
  border-radius: 8px;
  // border-radius: 9999px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.indicator {
  top: 3px;
  left: 3px;
  position: absolute;
  width: 174px;
  height: 174px;
  border-radius: 8px;
  animation-name: speaking-pulse;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

$cerulean-600: #7e44e5;
$cerulean-200: #9f72ef;

@keyframes speaking-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($cerulean-600, 0), 0 0 0 0 rgba($cerulean-200, 0);
  }
  5% {
    box-shadow: 0 0 0 6px rgba($cerulean-600, 1),
      0 0 0 0.25em rgba($cerulean-200, 0.75);
  }
  15% {
    box-shadow: 0 0 0 3px rgba($cerulean-600, 1),
      0 0 0.125em 0.25em rgba($cerulean-200, 0);
  }
  25% {
    box-shadow: 0 0 0 7px rgba($cerulean-600, 1), 0 0 0 0 rgba($cerulean-200, 0);
  }
  33% {
    box-shadow: 0 0 0 5px rgba($cerulean-600, 1), 0 0 0 0 rgba($cerulean-200, 0);
  }
  40% {
    box-shadow: 0 0 0 1px rgba($cerulean-600, 0), 0 0 0 0 rgba($cerulean-200, 0);
  }
  60% {
    box-shadow: 0 0 0 3px rgba($cerulean-600, 1),
      0 0 0 0.25em rgba($cerulean-200, 0.75);
  }
  80% {
    box-shadow: 0 0 0 9px rgba($cerulean-600, 1),
      0 0 0.125em 0.25em rgba($cerulean-200, 0);
  }
  90% {
    box-shadow: 0 0 0 2px rgba($cerulean-600, 1), 0 0 0 0 rgba($cerulean-200, 0);
  }
}

.circle {
  z-index: -1;
  border-radius: 50%;
  background-color: #7e44e5;
  width: 181px;
  height: 181px;
  position: absolute;
  top: -3px;
  left: -3px;
  opacity: 0;
  animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

.muted {
}

.screenshare {
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.inviteLink {
  width: 100%;
  height: 28px;
  color: #fff;
  font-size: 10px;
  background: #0d0d0e;
  font-weight: 500;
  outline: 0;
  text-align: left;
  border: 0;

  border: 1px solid hsla(0, 0%, 100%, 0.25);
  display: flex;
  align-items: center;
  border-radius: 6px;
  text-align: center;
  .link {
    cursor: pointer;
    padding-left: 2px;
    padding-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 34px);
    text-align: center;
  }

  .button {
    border-left: 0.07em solid hsla(0, 0%, 100%, 0.25);
    cursor: pointer;
    width: 34px;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
  }

  .icon {
    margin-left: 4px;
    margin-right: 4px;
    height: 10px;
    width: 10px;
    fill: white;
  }
}
.shareLink {
  font-size: 10px;
  text-align: center;
  margin-bottom: 6px;
}

.lockMessage {
  font-size: 10px;
  // max-width: 300px;
  text-align: center;
  margin-top: 6px;
}
