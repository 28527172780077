@import "src/styles/variables";

.errorWrapper {
  :global(.ant-spin-container) {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
}

.error {
  :global(.ant-spin-text) {
    color: white;
    font-weight: 600;
    text-shadow: none !important;
  }
}

.container {
  width: 320px;
  // background: black;
  //background: #1d2329;
  // background: #100f12;

  background: #0e0f12;
  // background: linear-gradient(135deg, rgba(16,15,18,1) 0%, rgb(0, 0, 0) 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .upgradeBannerContainer {
    padding-left: 18px;
    padding-right: 18px;
    margin-bottom: 12px;
    color: white;

    > div {
      padding: 10px 14px;
      background: #6825a8;
      border-radius: 6px;

      .upgradeBannerTitle {
        text-align: center;
        font-size: 17px;
        font-weight: 600;
        display: block;
      }

      .upgradeBannerSubtitle {
        display: block;
        text-align: center;
        font-size: 13px;
        margin-bottom: 4px;
      }

      .upgradeBannerButton {
        margin-top: 8px;
        width: 100%;
        background: #100b18;
        border: none;
      }
    }
  }

  .slideContainer {
    // padding-left: 14px;
    // padding-right: 14px;
    margin-top: 14px;
    position: relative;

    > div {
      display: flex;
      align-items: center;
      // border-radius: 6px;
      padding: 10px 18px;
      cursor: pointer;
    }

    .header {
      margin-right: 16px;
      font-weight: 600;
      font-size: 16px;
      color: white;
      display: flex;
      align-items: center;
      > svg {
        fill: white;
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      // &:hover {
      //   background: #303842;
      // }
    }
  }
  .roomsContainer {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 14px;
    position: relative;

    .roomsHeader {
      font-weight: 600;
      font-size: 16px;
      color: white;
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      > svg {
        fill: white;
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .roomItem {
      //background: #E8B80E;
      background: transparent;
      border-radius: 6px;
      padding: 10px 14px;
      margin-bottom: 4px;
      // margin-left: 8px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      color: white;
      position: relative;

      &:hover {
        background: #303842;
        .roomEditButton {
          opacity: 1;
        }
      }

      .roomEditButton {
        box-shadow: 0 2px 0 rgb(0 0 0 / 10%);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.1s cubic-bezier(0.17, 0.93, 0.38, 1);
        position: absolute;
        bottom: 10px;
        right: 4px;

        opacity: 0;
        border-radius: 4px !important;
        border: none;
        background-color: $background-color !important;

        height: 24px;
        padding: 4px 14px;
        > svg {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        &:hover {
          border: none;
          background-color: $secondary-color !important;
        }

        > span {
          font-weight: 500;
          font-size: 12px;
          color: white !important;
        }
      }

      .roomNameContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .roomName {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }

      .roomCounter {
        color: white;
        opacity: 0.8;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .roomParticipantContainer {
    display: flex;
    margin-top: 4px;
  }
}

.roomParticipantAvatar {
  font-weight: 700;
  // background: #e8b80e;
  // color: white;
  margin-right: 6px;
}

.flexGrow {
  flex-grow: 1;
}

.addRoomContainer {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  // padding: 8px;
  top: 0;
  right: 0;
  top: -2px;
  right: 27px;

  .addRoomButton {
    border-radius: 4px;
    background-color: transparent;
    box-shadow: 0 2px 0 rgb(0 0 0 / 10%);
    height: 24px;
    font-size: 12px;
    &:hover {
      background-color: $secondary-color !important;
    }

    padding: 2px;
    display: flex;
    align-items: center;

    > span {
      font-weight: 500;
      //font-size: 20px;
      color: white !important;
    }
  }
}

.collapse {
  position: absolute;
  top: 13px;
  left: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  // z-index: 1;

  background: black;
  > svg {
    width: 22px;
    height: 22px;
  }

  > span {
    font-size: 14px;
    font-weight: 500;
    color: white;
  }
}

.flex {
  padding-left: 18px;
  padding-right: 18px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.user {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 6px 12px;
  border-radius: 6px;
  transition: background-color 50ms linear;

  .userAvatar {
    border: none;
    // border-radius: 8px;
  }

  .name {
    margin-left: 12px;
    color: white;
    font-weight: 500;
  }

  &:hover {
    background: #5d21ff;
  }
}

.chatContainer {
  display: flex;
  justify-content: center;

  > span > div {
    cursor: pointer;
    width: 180px;
    padding: 10px 12px;
    border-radius: 6px;
    color: white;

    &:hover {
      background: #5d21ff8a;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      font-weight: 500;
      font-size: 14px;
      margin-right: 8px;
    }

    > svg {
      width: 22px;
      height: 22px;
    }
  }
}

.chatWrapper {
  height: max-content;
}
