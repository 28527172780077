@import "src/styles/variables";

.blackContainer {
  border-radius: 4px !important;
  border: 1px solid $background-color !important;
  background-color: $background-color !important;
  box-shadow: none;
  &:hover {
    border: 1px solid $background-color !important;
    background-color: $secondary-color !important;
  }

  > span {
    font-weight: 600;
    color: white !important;
  }
}

.whiteContainer {
  border-radius: 4px !important;
  border: 1px solid $background-color !important;
  background-color: $background-color !important;
  color: #212121 !important;

  &:hover {
    border: 1px solid $background-color !important;
    background-color: $secondary-color !important;
  }

  > span {
    font-weight: 600;
    color: #212121 !important;
  }
}
