@import "src/styles/variables";

// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap");

.editor {
  width: 100%;
  cursor: text;
  // height: 100%;

  // font-family: "Inter", ui-sans-serif, -apple-system, BlinkMacSystemFont,
  //   "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
  //   "Segoe UI Emoji", "Segoe UI Symbol";
  // -webkit-font-smoothing: auto;

  :global(.ProseMirror) {
    min-height: 60px;
  }

  :global(.ProseMirror-focused) {
    border: none;
    outline: none;
  }

  h1 {
    font-size: 3em;
    color: $slidesTextColorPrimary;

    margin-top: 0;
    margin-bottom: 0.2em;

    line-height: 1.05;
    min-height: 1.05em;
    // font-variation-settings: "wght" 649;
    letter-spacing: -0.0269em;
    // letter-spacing: -0.01em;
  }

  h2 {
    font-size: 2.4em;
    line-height: 1.1;
    min-height: 1.1em;
    // letter-spacing: -0.01em;
    // font-variation-settings: "wght" 649;
    letter-spacing: -0.0269em;

    margin-top: 0;
    margin-bottom: 0.2em;

    color: $slidesTextColorPrimary;
  }

  h3 {
    font-size: 2em;
    // font-weight: 700;
    line-height: 1.2;
    min-height: 1.4em;
    // letter-spacing: 0px;
    // font-variation-settings: "wght" 649;
    letter-spacing: -0.0269em;
    margin-top: 0;
    margin-bottom: 0;

    color: $slidesTextColorPrimary;
  }
  h4 {
    font-size: 1.4em;
    // font-weight: 700;
    line-height: 1.4;
    // min-height: 1.4em;
    // letter-spacing: 0px;
    // font-variation-settings: "wght" 649;
    letter-spacing: -0.0269em;
    margin-top: 0;
    margin-bottom: 0.2em;

    color: $slidesTextColorPrimary;
  }

  p {
    font-size: 1.4em;

    // font-weight: 700;
    line-height: 1.4;
    // min-height: 1.4em;
    // letter-spacing: 0px;

    // font-variation-settings: "wght" 456;
    // letter-spacing: 0.0075em;

    letter-spacing: -0.0269em;
    // letter-spacing: 0.0075em;

    margin-top: 0;
    margin-bottom: 0.2em;

    color: $slidesTextColorSecondary;
    // line-height: 1.4;
    // min-height: 1.4em;
  }

  strong {
    // font-variation-settings: "wght" 849;
  }
  ul,
  ol {
    padding: 0 1rem;
  }

  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   line-height: 1.1;
  // }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    border-radius: 0.5rem;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  mark {
    background-color: #faf594;
    padding: 0;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    border-left: 2px solid rgba(#0d0d0d, 0.1);
    padding-left: 1rem;
    font-style: italic;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  li {
    > p {
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.4;
      letter-spacing: -0.0269em;
      color: $slidesTextColorPrimary;
    }
  }

  li::marker {
    color: $slidesTextColorPrimary;
    font-size: 1.4em;
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    li {
      align-items: center;
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;

        > p {
          font-weight: 500;
          font-size: 1.4em;
          line-height: 1.4;
          letter-spacing: -0.0269em;
          color: $slidesTextColorPrimary;
        }
      }
    }
  }
}
