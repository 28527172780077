.ant-spin-dot-item {
  background-color: $main-color;
}

.ant-layout-header {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  background-color: $background-color;
}

.ant-layout {
  background-color: $background-color;
}

.ant-drawer-title {
  font-family: $font-family;
}

.ant-popover-inner {
  border-radius: 8px;
  font-family: $font-family;

  .ant-btn-primary {
    border-color: $background-color;
    background: $background-color;
  }
}
.ant-popover-arrow-content {
}

.ant-modal-content {
  font-family: $font-family;
}

.ant-form {
  font-family: $font-family;
}
