@import "src/styles/variables";

.allContainer {
  height: 100vh;
  position: relative;
}

.nameContainer {
  z-index: 2;
  padding: 4px 12px;
  height: 58px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    > img {
      margin-right: 6px;
    }
    > span {
      color: $slidesTextColorPrimary;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .right {
    display: flex;
    align-items: center;

    > span {
      opacity: 0;
    }
    > button {
      padding: 4px 6px;

      margin-left: 8px;
      color: $slidesTextColorPrimary;

      &:hover {
        color: $slidesTextColorPrimary !important;
      }
    }
  }
}
.wrapper {
  // padding-top: 44px;
  padding-bottom: 80px;
  display: flex;
  align-content: center;
  justify-content: center;
  background: $slidesBackground;
  height: 100%;
  overflow-y: scroll;
}

.container {
  position: relative;
  margin-top: 48px;
  // width: 800px;
  height: fit-content;
  // background: rgba($slidesBackground, 0.5);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  // border-radius: 8px;

  .containerAfter {
    border-radius: 8px;
    display: block;
    background: $slidesBackground;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  > div {
    background: $slidesElementsBorder;
  }
}
.cellActive {
  background: $slidesElementsBackground !important;
}

.cell {
  padding: 0.54em 0.77em;
  border-radius: 4px;
  // background: #292929;
  color: white;
  // transition: box-shadow 1s;

  &:hover {
    .editControls {
      opacity: 1;
      visibility: visible;
    }
  }

  .editControls {
    opacity: 0;
    visibility: hidden;

    position: absolute;
    top: -14px; //-34px;
    left: 50%;
    z-index: 1000;
    transform: translateX(-50%);

    > div {
      display: flex;
      background: rgba(255, 255, 255, 0.168);
      padding: 4px;
      border-radius: 2px;
      color: black;

      > button {
        align-items: center;
        display: flex;
        color: black;
        border-radius: 2px;
        background: white;
        font-size: 8px;
        height: 22px;
        margin-left: 2px;
        margin-right: 2px;
        padding: 0 4px;

        &:hover {
          background: rgb(223, 223, 223) !important;
        }

        > svg {
          width: 16px;
          height: 16px;
          fill: black;
        }
      }
    }
  }
}

.editableCell {
  background: transparent;

  :global(.react-resizable-handle) {
    opacity: 0;
    transition: opacity 200ms;
    &:after {
      width: 0.8em;
      height: 0.8em;
      border-right: 0.14em solid $slidesTextColorSecondary;
      border-bottom: 0.14em solid $slidesTextColorSecondary;
    }
  }

  &:hover {
    background: $slidesElementsHover;
    // box-shadow: 0px 0px 0px 2px $slidesElementsBorder;
    :global(.react-resizable-handle) {
      opacity: 1;
    }
  }
}

.layout {
  background: $slidesElementsBackground;
}

.image {
  background: $slidesElementsBackground;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
}

.control {
  position: fixed;
  z-index: 111;
  top: 8px;
}

.controls {
  max-width: 200px;
  width: 100%;
}

.sidebar {
  position: absolute;
  right: 10px;
  // top: 58px;

  top: 50%;
  transform: translateY(-50%);
}

.cellWrapper {
  width: 100%;
  height: 100%;
}

// .cellWrapperSelected {
//   box-shadow: 0px 0px 0px 2px $slidesElementsHover !important;
// }

.activeSlider {
  width: 3px;
  height: 24px;
  border-radius: 1.5px;
  background: $slidesTextColorPrimary;
}

.plotCounter {
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: 600;
  padding-left: 6px;
  font-size: 14px;
  color: $slidesTextColorSecondary;
  cursor: pointer;
}
.activePlotCounter {
  color: $slidesTextColorPrimary;
}

.slider {
  // background: $slidesElementsBorder;
  position: absolute;
  z-index: 111;
  // width: 114px;
  width: 86px;
  border-radius: 4px;
  // height: 114px;
  height: 110px;
  top: 0;
  left: 30px;
  overflow: hidden;

  border: 1.5px solid $slidesBackground;
  &:hover {
    .deleteIcon {
      display: block;
    }
  }

  .sliderWrapper {
    transform: scale(0.104) translate(-50%, -50%);
    position: relative;
    width: 800px;
    // height: fit-content;
    border-radius: 8px;

    width: 0;
    height: 0;

    > div {
      position: absolute;
    }
  }
}

.createNewPage {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  display: flex;
  justify-content: space-between;
  height: 30px !important;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $slidesTextColorPrimary;
    height: 100%;
    font-size: 10px;
    color: $slidesTextColorPrimary;
  }
}

.addImageWrapper {
  background: $slidesElementsBackground;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    fill: $slidesTextColorSecondary;
  }

  > div {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
.screenshareWrapper {
  background: $slidesElementsBackground;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  > div {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > span {
      color: $slidesTextColorSecondary;
    }
    > svg {
      fill: $slidesTextColorSecondary;
    }
  }
}

.deleteIcon {
  display: none;
  right: 4px;
  top: 0;
  position: absolute;
  z-index: 160;

  > span {
    cursor: pointer;
    background: white;
    border-radius: 2px;
    padding: 2px;
    font-size: 8px;
  }
}

.particpantContainer {
  left: 36px;
  bottom: -11px;
  position: absolute;
  z-index: 160;

  > span {
    opacity: 0;
    cursor: pointer;
    background: white;
    border-radius: 2px;
    padding: 4px;
    font-size: 6px;
  }
}
.cellParticpantContainer {
  left: 8px;
  top: -14px;
  position: absolute;
  z-index: 160;

  > span {
    opacity: 0;
    cursor: pointer;
    background: white;
    border-radius: 2px;
    padding: 4px;
    font-size: 6px;
  }
}

.iframe {
  background: $slidesElementsBackground;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  width: 100%;
  height: 100%;
  border: none;
}

.downArrow {
  transition: opacity 0.5s cubic-bezier(0.17, 0.93, 0.38, 1);
  opacity: 0;
  position: absolute;
  bottom: 8px;
  left: 4px;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
    fill: $slidesTextColorSecondary;
  }
}
.upArrow {
  transition: opacity 0.5s cubic-bezier(0.17, 0.93, 0.38, 1);
  opacity: 0;
  position: absolute;
  top: 8px;
  left: 4px;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
    fill: $slidesTextColorSecondary;
  }
}

.sliderPreviewPageContainer {
  &:hover {
    .downArrow {
      opacity: 0.5;
    }
    .upArrow {
      opacity: 0.5;
    }
  }
}

.cellControls {
  position: absolute;
  top: -14px; //-34px;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);

  > div {
    display: flex;
    background: rgba(255, 255, 255, 0.168);
    padding: 4px;
    border-radius: 2px;
    color: black;

    > button {
      align-items: center;
      display: flex;
      color: black;
      border-radius: 2px;
      background: white;
      font-size: 8px;
      height: 22px;
      margin-left: 2px;
      margin-right: 2px;
      padding: 0 4px;

      &:hover {
        background: rgb(223, 223, 223) !important;
      }

      > svg {
        width: 16px;
        height: 16px;
        fill: black;
      }
    }
  }
}

.grid {
  top: 0;
  left: 0;
  margin: 8px 10px;
  width: calc(100% - 20px);
  height: calc(100% - 22px);

  // background: wheat!important;
  position: absolute;
  border-radius: 4px;

  overflow: hidden;
  display: flex;
  // justify-content: space-between;
  > div {
    border: 1px solid #1a40f8;

    width: 4.16666667%;
    height: 100%;
    opacity: 0.06;
  }

  .line1 {
    background: $slidesTextColorSecondary;
  }
  .line2 {
    background: $slidesTextColorPrimary;
  }
}

.swiperSlide {
  height: 100vh;

  > div {
    pointer-events: none;
  }
}

.backgroundImage {
  position: absolute;
  pointer-events: none;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.logo {
  max-height: 58px;
}
