@import "src/styles/variables";

.connection {
  // position: absolute;
  // right: -24px;
  // top: 4px;
  // width: 14px;
  // height: 15px;
  margin-right: 4px;
}

.container {
  border-radius: 8px;
  position: relative;
  height: 100%;
  width: 100%;
}

.name {
  height: 26px;
  padding: 0 6px;
  width: max-content;
  margin: 8px auto 0 auto;
  background: rgb(29 35 41 / 90%);
  text-align: center;
  color: white;
  border-radius: 4px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;

  .muteIcon {
    z-index: 1;
    width: 23px;
    height: 28px;
  }

  > div {
    display: flex;
    align-items: center;
  }
}

.participant {
  background: $background-color;
  color: white;

  width: 100%;
  height: 100%;
  //max-height: 400px;
  //max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 18px 0px rgba(0, 0, 0, 0.3);
  // border: 3px solid $secondary-color;

  //overflow: hidden;

  // box-shadow: inset 0px 0px 20px 5px rgba(0, 0, 0, 0.6);
}

.avatar {
  border-radius: 8px !important;
  border: none;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    background: #181b1d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.video {
  // border-radius: 6%;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.indicator {
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  //z-index: 1;
  //background-color: green;

  animation-name: speaking-pulse;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

$cerulean-600: #7e44e5;
$cerulean-200: #9f72ef;

@keyframes speaking-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($cerulean-600, 0), 0 0 0 0 rgba($cerulean-200, 0);
  }
  5% {
    box-shadow: 0 0 0 6px rgba($cerulean-600, 1),
      0 0 0 0.25em rgba($cerulean-200, 0.75);
  }
  15% {
    box-shadow: 0 0 0 3px rgba($cerulean-600, 1),
      0 0 0.125em 0.25em rgba($cerulean-200, 0);
  }
  25% {
    box-shadow: 0 0 0 7px rgba($cerulean-600, 1), 0 0 0 0 rgba($cerulean-200, 0);
  }
  33% {
    box-shadow: 0 0 0 5px rgba($cerulean-600, 1), 0 0 0 0 rgba($cerulean-200, 0);
  }
  40% {
    box-shadow: 0 0 0 1px rgba($cerulean-600, 0), 0 0 0 0 rgba($cerulean-200, 0);
  }
  60% {
    box-shadow: 0 0 0 3px rgba($cerulean-600, 1),
      0 0 0 0.25em rgba($cerulean-200, 0.75);
  }
  80% {
    box-shadow: 0 0 0 9px rgba($cerulean-600, 1),
      0 0 0.125em 0.25em rgba($cerulean-200, 0);
  }
  90% {
    box-shadow: 0 0 0 2px rgba($cerulean-600, 1), 0 0 0 0 rgba($cerulean-200, 0);
  }
}

.circle {
  z-index: -1;
  border-radius: 8px;
  background-color: #7e44e5;
  width: 181px;
  height: 181px;
  position: absolute;
  top: -3px;
  left: -3px;
  opacity: 0;
  animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

.muted {
}

.screenshare {
  background-color: $background-color;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.inviteLink {
  width: 100%;
  height: 48px;
  border-radius: 12px 0 0 12px;
  color: #fff;
  font-size: 16px;
  background: #0d0d0e;
  font-weight: 500;
  outline: 0;
  text-align: left;
  border: 0;

  border: 1px solid hsla(0, 0%, 100%, 0.25);
  display: flex;
  align-items: center;
  border-radius: 12px;
  .link {
    cursor: pointer;
    padding-left: 4px;
    padding-right: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 74px);
    text-align: center;
  }

  .button {
    border-left: 0.07em solid hsla(0, 0%, 100%, 0.25);
    cursor: pointer;
    width: 74px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .icon {
    margin-left: 12px;
    margin-right: 4px;
    height: 16px;
    width: 16px;
    fill: white;
  }
}
.shareLink {
  margin-bottom: 6px;
}

.lockMessage {
  // max-width: 300px;
  text-align: center;
  margin-top: 6px;
}
