@import "src/styles/variables";

.countdownText {
  display: flex;
  align-items: center;
  width: 189px;
  text-align: center;
  justify-content: center;
  color: white;
  font-weight: 600;

  flex-direction: column;

  .startingIn {
    font-size: 20px;
    font-size: 15px;
    // margin-right: 4px;
  }
  .counter {
    color: rgb(242, 107, 77);
    width: 77px;
    font-size: 18px;
    text-align: left;
  }
}
.roomControls {
  height: 56px;
  z-index: 2;
  position: absolute;
  bottom: 8px;
  display: flex;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(0, 0, 0, 0.66);
  border-radius: 8px;

  button {
    // height: 45px;
    padding: 8px 10px;
    border: none;
    background: transparent;
    //background: rgb(50 18 138 / 70%);
    color: white;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    width: max-content;
    transition: 300ms background-color linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &:hover {
      background: rgb(93, 33, 255);
    }

    svg {
      align-self: center;
    }

    &:first-child {
      svg {
        font-size: 18px;
        padding-bottom: 4px;
        padding-top: 3px;
      }

      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      padding: 8px 4px;
      font-size: 8px;
    }
    :global(.fa-microphone) {
      //margin-right: 4px;
    }

    :global(.fa-microphone-slash) {
      //margin-right: 4px;
    }

    :global(.fa-video) {
      //margin-right: 4px;
    }

    :global(.fa-video-slash) {
      //margin-right: 4px;
    }

    :global(.fa-desktop) {
      //margin-right: 4px;
    }
  }

  .button {
    > svg {
      font-size: 18px;
      padding-bottom: 4px;
      padding-top: 3px;
    }
    // margin-left: 6px;
  }

  .mainButton {
    svg {
      font-size: 16px;
      padding-bottom: 4px;
      padding-top: 3px;
    }
    border-radius: 8px;
  }

  > div {
    display: flex;
  }
}

.error {
  position: absolute;
  bottom: 0;
}

.redButton {
  border-radius: 8px !important;
  background: red !important;
}

.tooltip {
  font-size: 8px;
  max-width: 140px;
}
