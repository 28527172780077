$main-color: #85e8ff;
$background-color: #000000; // #151716; //rgba(24, 23, 28);
$secondary-color: #32128a; //#292841;
$active-color: #5d21ff;
$hover-color: rgb(133 232 255 / 60%);
$font-family: "Outfit", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
$border-radius: 8px;
$yellow: #c9a01f;

$light-black: #0a0b1e;
$light-background: #f4f5f6;
$light-hover: #ebeced;

// :root {
//   --slides-background: black;
//   --slides-elements-border: #141414;
//   --slides-elements-background: #292929;
//   --slides-text-color-primary: white;
//   --slides-text-color-secondary: #959595;
// }
:root {
  --slides-background: transparent;
  --slides-elements-border: transparent;
  --slides-elements-hover: transparent;
  --slides-elements-background: transparent;
  --slides-preview-background: transparent;
  --slides-text-color-primary: transparent;
  --slides-text-color-secondary: transparent;
}
$slidesBackground: var(--slides-background);
$slidesElementsBorder: var(--slides-elements-border);
$slidesElementsHover: var(--slides-elements-hover);
$slidesElementsBackground: var(--slides-elements-background);
$slidesTextColorPrimary: var(--slides-text-color-primary);
$slidesTextColorSecondary: var(--slides-text-color-secondary);

$slidesPreviewBackground: var(--slides-preview-background);

// $slidesBackground
// $slidesElementsBackground
// $slidesElementsBorder
// $slidesTextColorPrimary
// $slidesTextColorSecondary
// $slidesPreviewBackground
