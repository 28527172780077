@import "src/styles/variables";

.container {
  border: 2px solid transparent;
  border-radius: 8px;
  transition: border 100ms ease-out;
  z-index: 1111;

  max-width: 4000px !important;
  max-height: 4000px !important;
  &:hover {
    border: 2px solid white;

    .leftTopDot {
      width: 8px;
      height: 8px;
      border-radius: 9999px;
      position: absolute;
      //padding: 6px;
      background: white;
      top: -12px;
      left: -12px;
    }

    .leftBottomDot {
      width: 8px;
      height: 8px;
      border-radius: 9999px;
      position: absolute;
      //padding: 6px;
      background: white;
      bottom: -12px;
      left: -12px;
    }

    .rightTopDot {
      width: 8px;
      height: 8px;
      border-radius: 9999px;
      position: absolute;
      //padding: 6px;
      background: white;
      top: -12px;
      right: -12px;
    }

    .rightBottomDop {
      width: 8px;
      height: 8px;
      border-radius: 9999px;
      position: absolute;
      //padding: 6px;
      background: white;
      bottom: -12px;
      right: -12px;
    }
  }

  &:hover {
    .draggableArea {
      display: flex;
    }
  }

  .draggableArea {
    z-index: 111;
    padding: 10px 14px;
    display: none;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: -36px;
    position: absolute;
    //z-index: 1;
    width: max-content;
    background: white;
    border-radius: 12px;
    > div {
      width: 40px;
      height: 40px;
      margin-left: 8px;
      margin-right: 8px;

      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      color: red;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.full {
  height: 100%;
  position: relative;
  width: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.link {
  height: 100%;
  background: #001529;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 22px;
  font-weight: 600;
  padding: 14px;
  > a {
  }
}

.editor {
  user-select: text;
  color: $background-color !important;
  background: white;
  padding: 8px;

  width: 100%;
  height: 100%;
}

.image {
  pointer-events: none;
  user-select: auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.screenshareContainer {
  height: 100%;
  width: 100%;
  background: #001529;

  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
    color: white;
  }
}
