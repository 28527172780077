@import "src/styles/variables";

.container {
  position: fixed;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  z-index: 1;
  flex-direction: column;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card {
  width: 100%;
  align-self: center;
  background: white;
  text-align: center;
  max-width: 460px;
  padding: 24px;
  border-radius: $border-radius;
  margin: 20px auto;

  .loginButton {
    margin-top: 30px;
    width: 100%;
  }
}

.note {
  text-align: center;
  color: white;

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}

.logo {
  > svg {
    max-width: 200px;
    display: block;
    margin: 0 auto;
  }
}
