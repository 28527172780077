@import "src/styles/variables";

.createNewPage {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  display: flex;
  justify-content: space-between;
  height: 40px !important;

  display: flex;
  justify-content: flex-end;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 88px;
    border-radius: 4px;
    background: $slidesElementsBackground;
    border: 1.5px solid $slidesElementsBorder;
    height: 100%;
    font-size: 10px;
    color: $slidesElementsBackground;

    > svg {
      fill: $slidesTextColorSecondary;
      width: 28px;
      height: 28px;
    }
  }
}

.item {
  padding: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  img {
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);
    width: 212px;
    height: 148px;
    object-fit: cover;
    border-radius: 4px;
  }

  span {
    margin-top: 4px;
  }

  &:hover {
    background: rgb(240, 240, 240);
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
  }
}
