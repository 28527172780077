@import "src/styles/variables";

.chat {
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.shareScreenContainer {
}
