@import "src/styles/variables";

.container {
  position: absolute;
  top: 58px;
  left: 4px;
  position: absolute;
  display: flex;
  flex-direction: column;

  > div {
    height: calc(100vh - 58px);
    overflow: scroll;
    padding: 2px;

    > div {
      width: 117px;
      height: 111px;
      margin-top: 10px;
      position: relative;

      display: flex;
      align-content: center;
      align-items: center;
    }
  }
}
.page {
  &:hover {
    .downArrow {
      opacity: 0.5;
    }
    .upArrow {
      opacity: 0.5;
    }
  }
}

.activeIndicator {
  width: 3px;
  height: 24px;
  border-radius: 1.5px;
  background: $slidesTextColorPrimary;
}

.number {
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: 600;
  padding-left: 6px;
  font-size: 14px;
  color: $slidesTextColorSecondary;
  cursor: pointer;
}
.activeNumber {
  color: $slidesTextColorPrimary;
}
.downArrow {
  transition: opacity 0.5s cubic-bezier(0.17, 0.93, 0.38, 1);
  opacity: 0;
  position: absolute;
  bottom: 8px;
  left: 4px;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
    fill: $slidesTextColorSecondary;
  }
}
.upArrow {
  transition: opacity 0.5s cubic-bezier(0.17, 0.93, 0.38, 1);
  opacity: 0;
  position: absolute;
  top: 8px;
  left: 4px;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
    fill: $slidesTextColorSecondary;
  }
}
.previewWrapper {
  background: $slidesPreviewBackground;
  position: absolute;
  z-index: 111;
  // width: 114px;
  width: 86px;
  border-radius: 4px;
  // height: 114px;
  height: 110px;
  top: 0;
  left: 30px;
  overflow: hidden;

  border: 1.5px solid $slidesElementsBorder;
  &:hover {
    .deleteIcon {
      display: block;
    }
  }

  .deleteIcon {
    display: none;
    right: 4px;
    top: 0;
    position: absolute;
    z-index: 160;

    > span {
      cursor: pointer;
      background: white;
      border-radius: 2px;
      padding: 2px;
      font-size: 8px;
    }
  }
  .sliderWrapper {
    transform: scale(0.104) translate(-50%, -50%);
    position: relative;
    width: 800px;
    // height: fit-content;
    border-radius: 8px;

    width: 0;
    height: 0;

    > div {
      position: absolute;
    }
  }
}
.particpantContainer {
  left: 36px;
  bottom: -11px;
  position: absolute;
  z-index: 160;

  > span {
    opacity: 0;
    cursor: pointer;
    background: white;
    border-radius: 2px;
    padding: 4px;
    font-size: 6px;
  }
}
