@import "../../../../styles/variables";

.inviteLink {
  margin-top: 12px;
  width: 100%;
  max-width: 500px;
  height: 48px;
  border-radius: 12px 0 0 12px;
  color: #fff;
  font-size: 16px;
  background: #0d0d0e;
  font-weight: 500;
  outline: 0;
  text-align: left;
  border: 0;

  border: 1px solid hsla(0, 0%, 100%, 0.25);
  display: flex;
  align-items: center;
  border-radius: 12px;
  .link {
    cursor: pointer;
    padding-left: 4px;
    padding-right: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 74px);
    text-align: center;
  }

  .button {
    border-left: 0.07em solid hsla(0, 0%, 100%, 0.25);
    cursor: pointer;
    width: 74px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .icon {
    margin-left: 12px;
    margin-right: 4px;
    height: 16px;
    width: 16px;
    fill: white;
  }
}

.field {
  width: 100%;
  font-size: 17px;
  margin-top: 34px;
  padding-top: 10px;
  border-top: 2px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.segment {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  > div {
    > label {
      width: 50%;
    }
  }
  .segmentItem {
    padding: 6px;
    //height: 100px;

    > span {
      font-weight: 600;
      font-size: 16px;
    }
    > p {
      line-height: normal;
      white-space: normal;
      text-align: center;
    }
  }
}
