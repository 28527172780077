@import "src/styles/variables";

.close {
  height: 40px;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: rgb(16 15 18 / 60%);

  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  padding: 14px;
  > span {
    color: white;
    font-weight: 600;
    cursor: pointer;
  }
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 14px;
  height: 100%;
  width: 320px;
  //background: #1d2329;
  background: rgb(16 15 18 / 70%);
  .grow {
    flex-grow: 1;
  }

  .messages {
    // flex-grow: 1;
    overflow: scroll;
  }

  .content {
    width: 100%;
    > span {
      color: white;
      margin-bottom: 4px;
      display: block;
    }
    display: flex;
    flex-direction: column;

    .title {
      .name {
        display: block;
      }
      .time {
        font-size: 10px;
        display: block;
        color: rgb(152, 152, 152);
      }
    }
  }

  .inputContainer {
    > form {
      display: flex;

      > input {
        height: 40px;
      }

      > button {
        height: 40px !important;

        width: 140px;
        background-color: $secondary-color !important;
        border-color: $secondary-color !important;
        border-radius: 0 !important;

        > span {
          line-height: 16px;
          font-size: 16px !important;
        }
      }
    }
  }

  .message {
    line-break: anywhere;
    cursor: pointer;
    display: flex;

    margin-bottom: 14px;

    .avatar {
      margin-right: 6px;
    }

    .text {
      width: max-content;
      font-weight: 500;
      max-width: 242px;
      font-family: $font-family;
      padding: 8px 14px;
      border-radius: 6px;
      background-color: #252538;
      color: #ffffff;

      display: flex;
      align-items: center;

      > div {
        // word-break: break-word;
        // white-space: pre-wrap;
      }
    }
  }

  .selfMessage {
    line-break: anywhere;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 14px;

    .content {
      margin-right: 6px;
      text-align: right;
    }

    .text {
      width: max-content;
      text-align: right;
      font-weight: 500;
      max-width: 242px;
      font-family: $font-family;
      padding: 8px 14px;
      border-radius: 6px;
      background-color: $active-color;
      color: #ffffff;

      margin-left: auto;
      margin-right: 0;
      // margin-right: 6px;

      > div {
        // word-break: break-word;
        // white-space: pre-wrap;
      }
    }

    .avatar {
    }
  }
}
