@import "src/styles/variables";

.defaultContainer {
  // position: absolute;
  // bottom: 60px;

  // max-width: 90%;
  // left: 50%;
  // transform: translate(-50%, 0);

  margin-bottom: 16px;
  height: 100vh;
  overflow-y: scroll;
  background: #0e0f12;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fullscreenContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  z-index: 2;
  overflow-y: scroll;
  display: flex;

  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 18px 18px 80px 18px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.largeParticipant {
  padding: 12px;
  margin-top: 14px;
  margin-bottom: 14px;
  max-width: calc(100vw - 320px);
  max-height: calc(100vw - 320px);
}
.participant {
  padding: 9px;
}

.big2 {
  width: calc(40vw - 160px);
  height: calc(40vw - 160px);
}

.big3 {
  width: 20vw;
  height: 20vw;
}

.big4 {
  width: 16vw;
  height: 16vw;
}

.small {
  width: 20vh;
  height: 20vh;
}

.fullscreenShareContainer {
  background-color: rgb(0 0 0 / 87%);
  max-height: calc(70vh - 62px);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.exitFocusModeButton {
  position: absolute;
  right: 10px;
  bottom: 10px;

  > span {
    font-weight: 500;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 8px;
    margin-right: 8px;
    width: max-content;
    background: $secondary-color;
    color: white;
    font-size: 12px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

.status {
  padding: 6px;
  color: #fff;
  font-size: 12px;
  background: #0d0d0e;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
  width: max-content;
  align-self: center;
}
