@import "src/styles/variables";

.avatar {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 12px;
  }
}

.switch {
  width: 100%;
  margin-top: 32px;
  padding-top: 12px;
  border-top: 2px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  > span {
    max-width: 80%;
    font-size: 15px;
  }
}

.center {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.background {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 12px;
  }
}

.gridItem {
  background-color: $background-color;
  padding: 12px;
  display: flex;
  align-items: center;

  .previewImage {
    object-fit: cover;
    cursor: pointer;
    width: 100%;
  }
}

.date {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
}
.switch {
  margin-top: 32px;
  padding-top: 12px;
  border-top: 2px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  > span {
    max-width: 80%;
    font-size: 15px;
  }
}

.pexels {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
