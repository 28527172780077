@import "../../../styles/variables";
.customPlan {
  > div {
    // border: 3px solid $active-color;
    cursor: pointer;

    background: #020202;
    color: white;
    padding: 18px;
    border-radius: 4px;
    -moz-outline-radius: 4px;
    display: flex;
    box-shadow: rgb(66 71 76 / 25%) 0px 0px 1px, rgb(10 11 30 / 6%) 0px 4px 8px,
      rgb(10 11 30 / 8%) 0px 8px 48px;

    > div {
      width: 50%;

      display: flex;
      justify-content: center;
      flex-direction: column;
      color: white;

      ul {
        margin: 0;
      }
    }
  }
}
.planName {
  text-align: left;
  font-size: 10px;
  font-weight: 400;
  color: white;
}

.planPrice {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: white;
  margin-bottom: 6px;
}
.planDesc {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 14px;
  color: #a3a3a3;
}

.grow {
  flex-grow: 1;
}

.planFeatures {
  > ul {
    margin-left: 0;
    padding-inline-start: 20px;

    > li {
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.section {
  > button {
    border: none;
    background: none;
    text-align: left;
  }

  .title {
    display: flex;
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 8px;
    font-weight: 800;
  }

  .panelHeader {
    font-size: 14px;
    font-weight: 600;
  }

  .panelText {
    font-size: 14px;
    font-weight: 400;
  }

  .item {
    color: $light-black;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    cursor: pointer;

    &:hover {
      background: rgb(235, 246, 255);
    }

    > div {
      &:first-child {
        width: 10%;
      }

      &:last-child {
        width: 90%;
        padding-left: 12px;

        > span {
          display: flex;

          &:first-child {
            font-weight: 600;
          }
        }
      }
    }
  }
}
.special {
  margin-top: 12px;
  padding: 4px 15px;

  display: block;
  background: #05aaff;
  color: white;
  font-size: 13px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
}
