.divider {
  background-color: rgba(#000, 0.1);
  height: 10px;
  margin-left: 4px;
  margin-right: 4px;
  width: 2px;
}

.container {
  background-color: #fff;
  border: 3px solid #0d0d0d;
  border-radius: 8px;
  color: #0d0d0d;
  display: flex;
  flex-wrap: wrap;
  //flex-direction: column;
  //max-height: 26rem;

  //flex: 1 1 auto;
  //overflow-x: hidden;
  //overflow-y: auto;
  padding: 10px;
  //-webkit-overflow-scrolling: touch;
}
