@import "src/styles/variables";

.button {
  button {
    min-height: 44px;
    width: 100%;
  }
}

.container {
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;

  .chat {
    background: rgba(0, 0, 0, 0.6);
    height: 100vh;
  }

  .wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      box-shadow: 0 0 18px 0px rgba(0, 0, 0, 0.1);
      width: 590px;
      background: white;
      //background: $background-color;
      border-radius: 8px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      height: max-content;

      max-height: 94vh;
      overflow-y: scroll;

      .title {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 6px;
      }

      .subTitle {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 24px;
      }

      label {
        color: white;
      }
    }
  }
}

.cell {
  background: white;
  border-radius: 12px;
}

.layout {
}

.slidesCardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.slide {
  margin-top: 18px;
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  box-shadow: 0px 0px 0px 1px #e3e3e3;
  cursor: pointer;

  > div {
    border-radius: 10px;
    min-height: 120px;
    height: 100%;
    width: 240px;
    padding: 14px;
    display: flex;
    flex-direction: column;

    .slideTitle {
      line-height: 24px;
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .slideDate {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
    }

    .slideActions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > span > svg {
        width: 23px;
        height: 16px;
      }
    }
  }
}
