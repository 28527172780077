@import "src/styles/variables";

.trigger {
  padding: 8px 8px;
  z-index: 1;
  cursor: pointer;
  > svg {
    width: 32px;
    height: 32px;
    fill: $slidesTextColorSecondary;
    opacity: 0.6;
    &:hover {
      fill: $slidesTextColorSecondary;
      opacity: 1;
    }
  }

  .selected {
    opacity: 1;
  }
}

.popover {
  // border-radius: 8px;
  padding: 8px;
  // background: $slidesElementsBorder;
}

.overlay {
  // border-radius: 8px;
  // padding: 8px;
  // background: $slidesElementsBorder;
}

.addElementContainer {
  display: flex;
  flex-direction: column;

  > div {
    cursor: pointer;
    margin: 0 auto;
    border-radius: 4px;
    width: 160px;
    // border: 1px solid black;
    margin-top: 6px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 6px 6px;
    color: black;
    border: 0.07em solid #e3e3e3;
  }
}

.title {
  font-weight: 600;
  font-size: 12px;
}

.body {
  font-size: 10px;
}

.background {
  display: flex;
  flex-direction: column;
  margin: 12px auto;
  justify-content: center;
  align-items: center;

  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 2px solid #ededed;

  > div {
    margin-top: 12px;
  }
}
