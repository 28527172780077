@import "src/styles/variables";

.trigger {
  padding: 8px 8px;
  z-index: 1;
  cursor: pointer;
  > svg {
    width: 32px;
    height: 32px;
    fill: $slidesTextColorSecondary;
    opacity: 0.6;
    &:hover {
      fill: $slidesTextColorSecondary;
      opacity: 1;
    }
  }
}

.popover {
  // border-radius: 8px;
  padding: 8px;
  // background: $slidesElementsBorder;
}

.overlay {
  // border-radius: 8px;
  // padding: 8px;
  // background: $slidesElementsBorder;
}

.addElementContainer {
  display: flex;
  flex-direction: column;

  > div {
    cursor: pointer;
    margin: 0 auto;
    border-radius: 4px;
    width: 160px;
    // border: 1px solid black;
    margin-top: 6px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 6px 6px;
    color: black;
    border: 0.07em solid #e3e3e3;
  }
}
.title {
  font-weight: 600;
  font-size: 12px;
}
.body {
  font-size: 10px;
}

// .option {
//   padding: 12px 12px;
//   font-family: $font-family;
//   font-weight: 600;
//   cursor: pointer;

//   &:hover {
//     background-color: #cbcbcb;
//     border-radius: 6px;
//   }
// }

// .item {
//   display: flex;
//   align-items: center;

//   > div > svg {
//     max-height: 20px;
//     max-width: 60px;
//     width: max-content;
//     margin-left: 12px;
//   }
//   > svg {
//     max-height: 20px;
//     max-width: 60px;
//     width: max-content;
//     margin-left: 12px;
//   }

//   span {
//   }
// }

// .maxWidth {
//   > div {
//     width: 100% !important;

//     .reactGiphySearchbox-listWrapper {
//       width: 100%;
//     }
//   }
// }

// .giphtWrapper {
//   width: 100%;
// }

// .instructionsContainer {
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 12px;
//   font-size: 16px;
//   font-weight: 500;

//   img {
//     max-width: 80%;
//     margin: 8px auto;
//     border-radius: 12px;
//     box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
//   }
// }
