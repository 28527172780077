.container {
  position: absolute;
  top: 0;
  transition: transform 0.5s cubic-bezier(0.17, 0.93, 0.38, 1);

  .text {
    position: absolute;
    bottom: -15px;
    background: white;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 500;
    width: max-content;
    color: black;
    border-radius: 6px;
  }
}
