@import "src/styles/variables";

.avatar {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 12px;
  }
}

.switch {
  width: 100%;
  margin-top: 32px;
  padding-top: 12px;
  border-top: 2px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  > span {
    max-width: 80%;
    font-size: 15px;
  }
}

.center {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.background {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 12px;
  }
}

.gridItem {
  background-color: $background-color;
  padding: 12px;
  display: flex;
  align-items: center;

  .previewImage {
    object-fit: cover;
    cursor: pointer;
    width: 100%;
  }
}

.inviteLink {
  width: 100%;
  height: 48px;
  border-radius: 12px 0 0 12px;
  color: #fff;
  font-size: 16px;
  background: #0d0d0e;
  font-weight: 500;
  outline: 0;
  text-align: left;
  border: 0;

  border: 1px solid hsla(0, 0%, 100%, 0.25);
  display: flex;
  align-items: center;
  border-radius: 12px;
  .link {
    cursor: pointer;
    padding-left: 4px;
    padding-right: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 74px);
    text-align: center;
  }

  .button {
    border-left: 0.07em solid hsla(0, 0%, 100%, 0.25);
    cursor: pointer;
    width: 74px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .icon {
    margin-left: 12px;
    margin-right: 4px;
    height: 16px;
    width: 16px;
    fill: white;
  }
}
