.divider {
  background-color: rgba(#000, 0.1);
  height: 10px;
  margin-left: 4px;
  margin-right: 4px;
  width: 2px;
}

.container {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  // margin-bottom: 10px;
}
