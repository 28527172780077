@import "src/styles/variables";

.blackContainer {
  width: 100%;
  height: 42px !important;
  border-radius: 4px !important;
  border: 1px solid $background-color !important;
  background-color: $background-color !important;

  &:hover {
    border: 1px solid $background-color !important;
    background-color: $secondary-color !important;
  }
  > span {
    font-weight: 600;
    font-size: 18px;
    color: white !important;
  }
}

.whiteContainer {
  width: 100%;
  height: 42px !important;
  border-radius: 4px !important;
  border: 1px solid white !important;
  background-color: white !important;

  &:hover {
    border: 1px solid #b1b1b1 !important;
    background-color: #b1b1b1 !important;
    color: #050505 !important;
  }
  > span {
    font-weight: 600;
    font-size: 18px;
    color: #050505 !important;
  }
}
