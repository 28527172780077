@import "src/styles/variables";

.container {
  margin-bottom: 12px;

  .error {
    display: block;
    text-align: left;
    color: red;
  }
  .label {
    color: #373737;
    font-weight: 400;
    font-size: 16px;
    display: block;
    text-align: left;
  }

  > input {
    border-radius: 4px;

    &:hover {
      border-color: $secondary-color !important;
    }

    &:focus {
      box-shadow: none;
      border-color: $secondary-color !important;
    }
  }

  > span {
    border-radius: 4px;
    &:hover {
      border-color: $secondary-color !important;
    }

    &:focus {
      box-shadow: none;
      border-color: $secondary-color !important;
    }
  }
}
