@import "src/styles/variables";

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.card {
  box-shadow: 0 0 18px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  align-self: center;
  background: white;
  text-align: center;
  max-width: 400px;
  padding: 24px;
  border-radius: $border-radius;
  margin: 20px auto;

  .loginButton {
    margin-top: 30px;
    width: 100%;
  }
}

.note {
  text-align: center;
  color: white;

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}

.logo {
  > * {
    max-width: 200px;
    display: block;
    margin: 0 auto;
  }
}

.avatar {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  > div {
    margin-top: 12px;
  }
}
