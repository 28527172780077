@import "../../../styles/variables";

@import "src/styles/variables";

.hidden {
  position: fixed;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: black;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 800ms, opacity 800ms;
  z-index: 1;
}

.visible {
  position: fixed;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: black;

  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 800ms;
  z-index: 1;
}

.block {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px;
  color: white;
  justify-content: center;

  > span {
    text-align: center;
    margin-bottom: 6px;
  }
}

.hidden {
  position: fixed;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: black;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 800ms, opacity 800ms;
  z-index: 1;
}

.visible {
  position: fixed;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: black;

  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 800ms;
  z-index: 1;
}

.block {
  display: flex;
  flex-direction: column;
  font-weight: 600;

  font-size: 16px;
  color: white;
  justify-content: center;

  > span {
    max-width: 200px;
    text-align: center;
    margin-bottom: 6px;
  }

  > svg {
    max-width: 120px;
    margin: 12px auto;
  }
}

.badge {
  z-index: 1;
  cursor: pointer;
  position: fixed;
  padding: 10px;
  border-radius: 6px 6px 0 0;
  bottom: 0;
  right: 10px;
  background: #1a40f6;
  color: white;
  font-weight: 500;

  &:hover {
    background: #0d34f3;
    color: white;
  }
}
