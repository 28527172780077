@import "src/styles/variables";

.container {
  background: white;
  border-radius: 8px;
  padding: 14px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.label {
  color: black;
  margin-bottom: 10px;
  text-align: center;
}

.segmentItem {
  display: flex;
  align-items: center;
  height: 34px;
  svg {
    width: 18px;
    height: 18px;
  }
}
