@import "src/styles/variables";

.editor {
  color: white !important;
  background: $background-color;
  padding: 8px;

  width: 100%;
  height: 100%;
}

.outer {
  touch-action: none;
  position: relative;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  overflow: hidden;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.addElement {
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 4px;
  background: $background-color;
  color: white;
  border-radius: 9999px;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.full {
  height: 100%;
  position: relative;
  width: 100%;
}

.screenshareContainer {
  height: 100%;
  width: 100%;
  background: #001529;

  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
    color: white;
  }
}

.zoomContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  border-radius: 12px;
  display: flex;

  .percent {
    background: $background-color;
    color: white;
    font-size: 12px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
  }

  button {
    border: none;
    background: $background-color;
    color: white;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 4px;

    > span {
      font-size: 12px;
    }
  }
}

.wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #2e1572;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23220d59' fill-opacity='0.48' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.background {
  cursor: grab;
  width: 6000px;
  height: 3375px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
