@import "src/styles/variables";

.addElement {
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 10px;
  left: 14px;
  padding: 4px;
  background: $background-color;
  color: white;
  border-radius: 9999px;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.popover {
  //background-color: $background-color;
}

.option {
  padding: 12px 12px;
  font-family: $font-family;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #cbcbcb;
    border-radius: 6px;
  }
}

.item {
  display: flex;
  align-items: center;

  > div > svg {
    max-height: 20px;
    max-width: 60px;
    width: max-content;
    margin-left: 12px;
  }
  > svg {
    max-height: 20px;
    max-width: 60px;
    width: max-content;
    margin-left: 12px;
  }

  span {
  }
}

.maxWidth {
  > div {
    width: 100% !important;

    .reactGiphySearchbox-listWrapper {
      width: 100%;
    }
  }
}

.giphtWrapper {
  width: 100%;
}

.instructionsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;

  img {
    max-width: 80%;
    margin: 8px auto;
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
  }
}
