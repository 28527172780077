@import "src/styles/variables";

.hidden {
  position: fixed;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: black;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 800ms, opacity 800ms;
  z-index: 1;
}

.visible {
  position: fixed;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background: black;

  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 800ms;
  z-index: 1;
}

.block {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px;
  color: white;
  justify-content: center;

  > span {
    text-align: center;
    margin-bottom: 6px;
  }
}
