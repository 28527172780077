@import "src/styles/variables";

.menu {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  //width: 100%;

  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: flex-end;
  }

  .indicator {
    height: 4px;
    background: #5d21ff;
  }
}

.container {
  > span {
    font-weight: 500;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 8px;
    margin-right: 8px;
    width: max-content;
    background: black;
    color: white;
    font-size: 12px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
  }
}

.popover {
  // max-width: 260px;
}

.keynotePopover {
  width: 471px;
}

.openPresentationButton {
  color: black;
  padding: 6px;
  cursor: pointer;
}
.presenter {
  z-index: 1;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    height: 4px;
  }

  > span {
    font-weight: 500;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 8px;
    margin-right: 8px;
    width: max-content;
    background: black;
    color: white;
    font-size: 12px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
  }
}

.exitButton {
  position: fixed;
  bottom: 4px;
  right: 0;
}

.presentationLink {
  min-width: 200px;
  padding: 12px;
  border-bottom: 1px solid black;
}
