@import "src/styles/variables";

.popupContainer {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  button {
    margin-top: 6px;
    margin-bottom: 6px;
    border-radius: $border-radius !important;
    border: 1px solid $background-color !important;
    background-color: $secondary-color !important;

    &:hover {
      border: 1px solid $background-color !important;
      background-color: $secondary-color !important;
    }

    > span {
      font-weight: 600;
      color: white !important;
    }
  }
}
.user {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 6px 12px;
  border-radius: 6px;
  transition: background-color 50ms linear;

  .userAvatar {
    border: none;
    border-radius: 8px;
  }

  .name {
    margin-left: 12px;
    color: white;
    font-weight: 500;
  }
  .role {
    margin-left: 4px;

    color: white;
    opacity: 0.8;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    flex-grow: 1;
    text-align: right;
  }

  &:hover {
    background: #5d21ff;
  }
}

.popover {
  padding: 14px;
  width: 200px;
  text-align: center;

  .close {
    margin-left: 6px;
    margin-right: 6px;
    cursor: pointer;
    text-decoration: underline;
  }
}
