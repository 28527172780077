@import "src/styles/variables";

.addImageWrapper {
  background: $slidesElementsBackground;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    fill: $slidesTextColorSecondary;
  }

  > div {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 8px;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.imageThemeBackground {
  background: $slidesElementsBackground;
}

.label {
  cursor: pointer;
}
