@import "src/styles/variables";

.button {
  padding: 8px 14px !important;
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  // margin-left: 6px;
  height: 56px;

  svg {
    font-size: 22px;
    // margin-bottom: 2px;
  }
  > span {
    margin-top: 1px;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 16px;
  }
}
