.cls1 {
  fill: transparent;
}

.fill {
  fill: #ffffff;
}
.cls2 {
  //fill: #ffffff;
}

.cls3 {
  //fill: #ffffff;
}

.cls4 {
  //fill: #ffffff;
}

.cls5 {
  //fill: #ffffff;
}

.cls6 {
  fill: transparent;
}
